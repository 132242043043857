/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'houses-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.207 1a1 1 0 00-1.414 0L.146 6.646a.5.5 0 00.708.708L1 7.207V12.5A1.5 1.5 0 002.5 14h.55a2.5 2.5 0 01-.05-.5V9.415a1.5 1.5 0 01-.56-2.475l5.353-5.354z"/><path pid="1" d="M8.793 2a1 1 0 011.414 0L12 3.793V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3.293l1.854 1.853a.5.5 0 01-.708.708L15 8.207V13.5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 014 13.5V8.207l-.146.147a.5.5 0 11-.708-.708z"/>',
    },
});
